<script>
import appConfig from "@/app.config";
import { http } from "../../../../helpers/easyindustriaapi/config";
import { authEasyMethods, notificationMethods } from "@/state/helpers";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";

export default {
  page: {
    // feita o ajuste
    title: "Aviso de Embarque",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: { Layout, PageHeader, Insert, List, Edit },
  data() {
    return {
      currentTabelas: {},
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Cadastro",
          href: "/",
          active: true,
        },
        {
          text: "Aviso de Embarque",
          href: "/carga_transporte/embarque",
          active: true,
        },
      ],

      //Unidade: undefined,
      //tabelas: [],
      grupos: undefined,
      tabelas: [],
      tabelas2: [],

      titleBody: "Grupos",
      editando: false,
      inserindo: false,
      showModal: false,
    };
  },

  created() {
    
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa")); // para saber a emrpessa atual;
    // storage esta no navegador em aplucation;descricaogrupo
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async getData() {
      this.onLoader();
      try {
        // let response = await http.get(
        //   "/motorista?motorista_id=" + -6
        // );
        // "/fornecedor?empresa_id=" + this.currentEmpresa.id + "&fornecedor_id=" + -2
        let response = await http.get(
          "/motorista?empresa_id=" + this.currentEmpresa.id  + "&motorista_id=" + -6
        );
        if (response.status === 200) {
          this.motoristas = response.data;
          console.log(this.motoristas)
        } else {
          this.motoristas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    setTabelas(tabelas) {
      this.currentTabelas = tabelas;
      //console.log(this.currentTabelas)
    },
    insert() {
      this.showModal = true;
      this.inserindo = !this.inserindo; // recebe true;
    },
    edit(oldTabelas) {
      
      this.setTabelas(oldTabelas);
      this.editando = !this.editando;
    },

    async doPost(embarque) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      embarque.empresa_id = this.currentEmpresa.id;
      // embarque.user_id = this.currentUser.id;
      this.onLoader();
      try {
        let response = await http.post(
          "/aviso-embarque?empresa_id=" + this.currentEmpresa.id,
          embarque
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doPut(rota) {
      this.onLoader();

      try {
        let response = await http.put(
          "/rota/" +
            rota.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          rota
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.makeToast("success", "Registro alterado");
            this.getData();
            this.editando = false;
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doDelete(rota) {
      console.log(rota);
      this.onLoader();

      try {
        let response = await http.delete(
          "/rota/" +
            rota.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          rota
        );
        if (response) {
          if (response.status === 200) {
            this.getData();
            this.offLoader();
            this.makeToast("warning", "Registro excluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    close_modal(){ // fecha o modal ao clicar;
      this.showModal = false;
    },
    back() {
      this.inserindo = false;
      this.editando = false;
      this.titleBody = "Embarque";
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button
                  @click="insert()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success"
                >
                  + Cadastrar Motorista
                </button>
                <button
                  @click="back()"
                  v-if="!isHide"
                  class="btn btn-secondary"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div v-if="!isHide" class="card-body">
            <Edit
              v-if="editando"
              :empresa="currentEmpresa"
              :oldTabelas="currentTabelas"
              :listGrupos2="tabelas"
              :ola="currentTabelas"
              @newTitle="changeHomeTitle"
              @doPut="doPut"
            />
            <Insert
              v-if="inserindo"
              :listGrupos="tabelas"
              :listGrupos2="tabelas"
              :currentEmpresa="currentEmpresa"
              @newTitle="changeHomeTitle"
              @doPost="doPost"
              :showModal="showModal"
            />
          </div>
          <!-- <b-modal
            v-model="showModal"
            id="modal-xl"
            size="xl"
            title="Tipo de Tabela de preços"
            hide-footer
          >
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_sigla_id" class="col-form-label">Nome</label>
                  <div class="col">
                    <input
                      v-model="tabelas.ttp_nome"
                      class="form-control text-leftt"
                      type="text"
                      placeholder="digite a Sigla"
                      id="sigla"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-10">
              <button class="btn btn-light" @click="close_modal">Gravar</button>
            </div>
          </b-modal> -->
          <List
            :currentEmpresa="currentEmpresa"
            :listTabelas="tabelas"
            :hide="isHide"
            @edit="edit"
            @newTitle="changeHomeTitle"
            @setTabelas="setTabelas"
            @doDelete="doDelete"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>