<script>
export default {
  props: {
      tabelas: {
          type: Object,
      },
  },
  computed: {
    getClasse() {
        switch (this.produto.produto_m_s) {
            case 'M':
                return 'Mercadoria';
            case 'S':
                return 'Serviço';
        
            default:
                return 'Produto';
        }
    }
  },
}
</script>

<template>
    <div id="produto">
        <p>Descrição: <strong>{{ tabelas.ttp_nome }}</strong></p>
        <p>Referência: <strong>{{ produto.codbarras }}</strong></p>
        <p>Classe: <strong>{{ getClasse }}</strong></p>
        <p>Tipo: <strong>{{ produto.tipoproduto.descricao }}</strong></p>
        <p>Grupo: <strong>{{ (produto.grupo) ? produto.grupo.descricao : '' }}</strong></p>
        <p>N. C. M.: <strong>{{ produto.produto_ncm }}</strong></p>
        <p>C. E. S. T.: <strong>{{ produto.produto_cest }}</strong></p>
        <p>Preço: <strong>{{ formatterCurrBR(produto.preco) }}</strong></p>
        <p>Custo: <strong>{{ formatterCurrBR(produto.preco_custo) }}</strong></p>
        <p>Atacado: <strong>{{ formatterCurrBR(produto.preco_atacado) }}</strong></p>
        <p>Margem lucro: <strong>{{ formatterFloatBR(produto.produto_margemlucro) }}%</strong></p>
        <p>Unidade medida: {{ (produto.unidade) ? produto.unidade.descricao : '' }}</p>
        <p>Classificação SPED: <strong>{{ (produto.classificacao_sped) ? produto.classificacao_sped.descricao : '' }}</strong></p>
        <p>Situação: <span v-bind:class="(parseInt(produto.ativo) === 0) ? 'text-danger' : 'text-success'">{{ (parseInt(produto.ativo) === 0) ? 'Inativo' : 'Ativo' }}</span></p>
    </div>
</template>