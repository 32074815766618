<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
//import Multiselect from "vue-multiselect";
export default {
  props: { 
      oldTabelas: { type: Object },
      listGrupos2: { type: Array },
     /// parentSelected: { type: Object, required: true }
       ola: {type: Object}
    },
  components: { 
   // Multiselect 
    },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Alterar Grupo',
      tabelas:{
        ttp_nome: null,
        
      },
      motorista: [
        {
          id: 1,
          nome: "motorista1",
        },
      ],
      veiculos: [
        {
          id: 1,
          nome: "veiculos1",
        },
      ],
      rotas: [
        {
          id: 1,
          nome: "rota1",
        },
      ],
      insert_embarque: {
        motorista_id: null,
        veiculo_id: null,
        rota_id: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      },
      select_motorista: null,
      select_rota: null,
      select_veiculos: null,
      rota: {
        nome: null,
      }, 
    }
  },
  validations: {
    tabelas:{
        ttp_nome: {required}, 
      }
  },
  created(){
   this.rota = this.oldTabelas
  },
  mounted() {
    this.setNewTitle();
    console.log(this.ola)
    this.insert_embarque = this.ola
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPut() {
      console.log('Gravou');
      console.log(this.insert_embarque)
      this.$emit('doPut', this.insert_embarque);
    },
    seleciona_moto(event) {
      //console.log(event)
      this.insert_embarque.motorista_id = event.id;
    },
    seleciona_rota(event) {
      this.insert_embarque.rota_id = event.id;
      //console.log(event)
    },
    seleciona_veiculo(event) {
      this.insert_embarque.veiculo_id = event.id;
      ///console.log(event)
    },
    doPut(){
      this.$emit('doPut',this.rota)
    }
  },
    
}
</script>

<template>

<div class="card">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <Label>Rota:</Label>
            <input
              v-model="rota.nome"
              type="text"
              class="form-control text-left"
              placeholder="digite a rota"
            />
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-4">
          <div class="col-md-12 text-center">
            <button class="bnt btn-secondary" @click="doPut()">salvar</button>
          </div>
        </div>
      </div>
    </div>
</template>